<template>
  <div>
    <v-dialog v-model="dialog" width="600px" persistent>
      <v-card v-if="dialog">
        <v-card-title>
          <div>
            <v-btn small absolute top right icon @click="closeDialog()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <v-col class="pl-0" cols="12">
            <h3>
              {{
                this.editProvider.id ? 'Editar Fornecedor' : 'Novo Fornecedor'
              }}
            </h3>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nome"
                  v-model="formProvider.name"
                  required
                  :rules="rules.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="CPF/CNPJ"
                  v-model="formProvider.cpfCnpj"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  required
                  :rules="rules.cpfCnpj"
                  :disabled="disableCpfCnpj"
                ></v-text-field>
              </v-col>
              <v-col class="pb-0" cols="12">
                <span>
                  Caso o fornecedor seja estrangeiro confirme como "Fornecedor
                  Estrangeiro"</span
                >
                <v-checkbox
                  label="Fornecedor Estrangeiro"
                  v-model="formProvider.foreign"
                ></v-checkbox>
              </v-col>
              <v-col class="pt-0" cols="12" lg="6" md="6" sm="12">
                <v-text-field
                  label="Telefone"
                  v-model="formProvider.phone"
                  v-mask="['(##)####-####', '(##)#####-####']"
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="12" lg="6" md="6" sm="12">
                <v-text-field
                  label="E-mail"
                  v-model="formProvider.email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-btn text @click="closeDialog()">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                :loading="loading"
                @click="saveProvider()"
              >
                {{ this.editProvider.id ? 'Editar' : 'Salvar' }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editProvider: {
      type: Object,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    ...mapGetters({
      providers: 'providers/listProvidersActive',
    }),
    filterCpfCnpj() {
      let list = []
      this.providers.filter((item) => {
        list.push({ id: item.id, cpfCnpj: item.cpfCnpj })
      })
      return list
    },
    disableCpfCnpj() {
      if (this.formProvider.foreign) {
        this.formProvider.cpfCnpj = null
        delete this.formProvider.cpfCnpj
      }
      return this.formProvider.foreign
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      formProvider: {
        id: null,
        name: null,
        cpfCnpj: null,
        phone: null,
        email: null,
        foreign: false,
      },
      rules: {
        name: [(v) => !!v || 'Por favor, preencha o nome '],
        cpfCnpj: [
          (v) => {
            if (!v) {
              return !this.formProvider.foreign
                ? 'Por favor, preencha o CPF ou CNPJ '
                : true
            } else if ((v || '').length < 14) {
              return !this.formProvider.foreign
                ? 'Por favor, preencha o CPF ou CNPJ válido '
                : true
            } else {
              return true
            }
          },
          (v) => {
            if (
              this.filterCpfCnpj.includes(
                this.filterCpfCnpj.find(
                  (item) => item.cpfCnpj == v && item.id != this.formProvider.id
                )
              )
            ) {
              return !this.formProvider.foreign
                ? 'CPF ou CNPJ já cadastrado'
                : true
            } else {
              return true
            }
          },
        ],
      },
    }
  },
  methods: {
    saveProvider() {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.formProvider.id) {
          this.updateProvider()
        } else {
          this.createProvider()
        }
      }
    },
    createProvider() {
      if (this.formProvider.phone) {
        this.formProvider.phone = this.formProvider.phone.replace(/\D/g, '')
      } else {
        delete this.formProvider.phone
      }
      this.$store
        .dispatch('providers/createProvider', this.formProvider)
        .then(() => {
          this.$store.dispatch('providers/getListProviders')
          this.loading = false
          this.dialog = false
        })
    },

    updateProvider() {
      if (this.formProvider.phone) {
        this.formProvider.phone = this.formProvider.phone.replace(/\D/g, '')
      } else {
        delete this.formProvider.phone
      }
      this.$store
        .dispatch('providers/updateProvider', this.formProvider)
        .then(() => {
          this.$store.dispatch('providers/getListProviders')
          this.loading = false
          this.dialog = false
        })
    },

    getDataProvider() {
      this.formProvider.id = this.editProvider.id
      this.formProvider.name = this.editProvider.name
      this.formProvider.cpfCnpj = this.editProvider.cpfCnpj
      this.formProvider.phone = this.editProvider.phone
      this.formProvider.email = this.editProvider.email
      this.formProvider.foreign = this.editProvider.foreign
    },

    closeDialog() {
      this.dialog = false
      this.formProvider = {
        id: null,
        name: null,
        cpfCnpj: null,
        phone: null,
        email: null,
        foreign: false,
      }
    },
  },
  watch: {},
  created() {
    this.getDataProvider()
  },
}
</script>
