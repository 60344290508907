<template>
  <v-dialog
    v-if="show"
    @input="dialogsChangeNewExpense($event)"
    :value="show"
    width="800px"
    persistent
  >
    <v-card class="px-4">
      <v-card-title>
        <div>
          <v-btn
            small
            absolute
            top
            right
            icon
            @click="dialogsChangeNewExpense(false)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <v-col class="pl-0" cols="12">
          <h3> <v-icon medium>trending_up</v-icon> {{ currentTitle }} </h3>
        </v-col>
      </v-card-title>
      <v-form
        v-model="valid"
        ref="form"
        :class="$vuetify.breakpoint.smAndUp ? 'pr-10 pl-5' : 'pr-0'"
      >
        <v-container>
          <p>Cadastre suas despesas referentes ao ano atual.</p>
          <v-row>
            <v-col cols="12" md="12" sm="12" lg="12">
              <v-autocomplete
                :items="category"
                label="Categoria da Despesa"
                item-text="name"
                item-value="id"
                v-model="expense.idCategory"
                :rules="rules.category"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="5" sm="12" lg="5">
              <base-input-date
                :input="{ label: 'Data da despesa', rules: rules.date }"
                :picker="{ type: 'date' }"
                v-model="expense.dateReference"
              ></base-input-date>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="5" sm="12" lg="6" class="pt-0 mt-0">
              <base-input-money v-model="expense.value"></base-input-money>
            </v-col>
            <!-- <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer> -->
          </v-row>

          <span class="font-weight-regular subtitle-2"
            >Busque pelo fornecedor ou cadastre um novo:</span
          >
          <v-row>
            <v-col cols="12" md="12" sm="12" lg="12">
              <v-autocomplete
                label="CNPJ ou CPF do Fornecedor"
                :items="listProviders"
                :item-text="itemProviders"
                no-data-text="Não há fornecedores"
                item-value="id"
                v-model="expense.idProvider"
                :rules="rules.document"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-btn
                color="#1565C0"
                rounded
                small
                outlined
                dark
                @click="insertProvider = true"
                >Novo fornecedor</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" lg="12" class="mt-2">
              <v-text-field
                label="Descrição da Despesa"
                :rules="rules.description"
                v-model="expense.description"
              ></v-text-field>
            </v-col>
          </v-row>
          <transition name="fade">
            <v-row v-if="!newFile">
              <v-col cols="12" sm="12">
                <span>
                  Baixar Nota Fiscal ou comprovante da Despesa:
                </span>
                <p>
                  <v-btn icon>
                    <k-download :id="expense.fileId"></k-download>
                  </v-btn>
                  <v-btn text color="primary" @click="newFile = !newFile">
                    {{ computedTextAlterBtn }}
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </transition>
          <transition name="fade">
            <template v-if="newFile">
              <v-row>
                <v-col cols="12">
                  <sub class="text--secondary"
                    >Nota Fiscal ou comprovante da despesa - Não obrigatório - (
                    Pode ser uma foto )</sub
                  >
                  <input-file
                    label="Clique no botão para selecionar"
                    label-send="Arquivo enviado com Sucesso!"
                    v-model="expense.fileId"
                    :loading="uploadindFile"
                    @change="setFile"
                    :real-file-name="realFileName"
                    :hide-label-on-content="hideLabelOnContent"
                  ></input-file>
                </v-col>
              </v-row>
            </template>
          </transition>
        </v-container>
        <v-card-actions class="pb-10">
          <v-btn
            text
            :disabled="loading"
            @click="dialogsChangeNewExpense(false)"
          >
            CANCELAR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            :disabled="!valid"
            @click="saveExpense()"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
      <formProvider
        v-model="insertProvider"
        v-if="insertProvider"
        :editProvider="{}"
      ></formProvider>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import inputFile from '@src/components/widget/InputFile.vue'
import kDownload from '@src/components/widget/Download.vue'
import formProvider from '@src/components/provider/formProvider.vue'
import { mask, masked } from 'vue-the-mask'

export default {
  data() {
    return {
      yearNow: this.$moment()
        .hour(0)
        .minute(0)
        .second(0)
        .milliseconds(0)
        .format('Y'),
      insertProvider: false,
      loading: false,
      uploadindFile: false,
      realFileName: true,
      hideLabelOnContent: true,
      rules: {
        value: [
          (v) =>
            /^([Rr]\$[\s]*)?((([\d]{1,3})(\.[\d]{3})*?)|[\d]+)(,[\d]{2})?[\s]*?$/.test(
              v
            ) || 'Valor inválido!',
        ],
        category: [(v) => !!v || 'Categoria é obrigatorio!'],
        date: [
          (v) => !!v || 'Data é obrigatorio!',
          (v) => {
            let yearExpense = this.expense.dateReference
              ? this.expense.dateReference.split('-')[0]
              : this.yearNow
            if (yearExpense < this.yearNow) {
              return `O ano não pode ser anterior ao de ${this.yearNow}`
            } else {
              return true
            }
          },
        ],
        description: [(v) => !!v || 'Descrição é obrigatorio!'],
        document: [(v) => !!v || 'O fornecedor é obrigatorio!'],
      },
      valid: false,
      newFile: true,
      title: '',
      mask: '',
      listProviders: null,
    }
  },
  computed: {
    ...mapState({
      show: (state) => state.dialogs.newExpense,
      expense: (state) => state.dialogs.expense,
      category: (state) => state.expenses.category.data,
      // loading: (state) => state.expenses.loading,
    }),
    ...mapGetters({
      providersActive: 'providers/listProvidersActive',
      providersAll: 'providers/listProvidersAll',
    }),
    ...mapState({
      provider: 'providers/listProviders',
    }),
    computedTextAlterBtn() {
      if (this.expense.fileId) return 'Alterar comprovante'
      return 'Subir comprovante'
    },
    currentTitle() {
      if (this.expense.id) return 'Editar Despesa'
      return 'Nova Despesa'
    },
  },
  components: {
    inputFile,
    kDownload,
    formProvider,
  },
  directives: {
    mask,
    masked,
  },
  methods: {
    ...mapActions({
      dialogsChangeNewExpense: 'dialogs/changeNewExpense',
      getExpenseCategory: 'expenses/getExpenseCategory',
      createNewExpense: 'expenses/createNewExpense',
      updateExpense: 'expenses/updateExpense',
    }),
    setFile(file) {
      this.uploadindFile = true
      this.expense.file = file
      this.uploadindFile = false
    },
    saveExpense() {
      let data = Object.assign({}, this.expense)
      this.loading = true
      data.value = this.removeMask(data.value)
      if (data.id) {
        this.updateExpense(data).then((res) => {
          this.actionCorrectInsert()
        })
      } else {
        console.log(data)
        this.createNewExpense(data)
          .then((res) => {
            this.actionCorrectInsert()
          })
          .catch((error) => {
            data.dateReference = this.$moment().format('YYYY-MM-D')
            this.createNewExpense(data)
            this.actionCorrectInsert()
          })
      }
    },
    removeMask(value) {
      return value.replace(/\D/g, '')
    },
    itemProviders(item) {
      return `${item.cpfCnpj ? item.cpfCnpj : 'Estrangeiro'} - ${item.name}`
    },
    actionCorrectInsert() {
      this.$store.dispatch('expenses/getExpenses')
      this.$store.commit('snackbarFlashMessage/setSuccessRegister')
      this.dialogsChangeNewExpense(false, {})
      this.loading = false
    },
  },
  mounted() {
    this.getExpenseCategory()
    this.show
    this.$store.dispatch('providers/getListProviders')
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue) {
        if (this.expense.id) {
          this.listProviders = this.providersAll
          this.newFile = !this.expense.fileId
          this.expense.value = this.$options.filters.currencyFromCents(
            this.expense.value
          )
        } else {
          this.listProviders = this.providersActive
          this.newFile = true
        }
      }
    },
    insertProvider(newValue, oldValue) {
      this.$store.dispatch('providers/getListProviders')
      if (this.expense.id) {
        this.listProviders = this.providersAll
      } else {
        this.listProviders = this.providersActive
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.descricao
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)
  position: absolute
  color: back
  top: 45px

.k-sub-file
  color: rga(0,0,0,.54)
  bottom: -2.25em

.fade-enter-active, .fad-leave-active
  transition: opaity .5s


.fade-enter, .fade-leave-o
  opacity: 0
</style>
